import { createApp } from 'vue';
import CustomButton from "@components/CustomButton.vue";
import BackButton from "@components/BackButton.vue";
import AnimatedIcons from "@components/AnimatedIcons.vue";
import Drawer from "@components/Drawer.vue";
import SubtleButton from "@components/SubtleButton.vue";
import Card from "@components/Card.vue";
import Accordion from "@components/Accordion.vue";
import CustomMap from "@components/CustomMap.vue";
import StickyGrid from "@components/StickyGrid.vue";
import { AccordionContent, AccordionHeader, AccordionItem, AccordionRoot, AccordionTrigger } from 'radix-vue'

import '@styles/app.pcss';
import fadeEnter from '@scripts/directives/fade-enter'

const app = createApp({
    components: {
        CustomButton,
        BackButton,
        AnimatedIcons,
        Drawer,
        SubtleButton,
        Card,
        Accordion,
        CustomMap,
        StickyGrid,
        AccordionContent,
        AccordionHeader,
        AccordionItem,
        AccordionRoot,
        AccordionTrigger,
    },
});

app.directive('fade-enter', fadeEnter);

app.mount('#app');
